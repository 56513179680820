const Glide = require('@glidejs/glide').default;
const gliders = Array.from(document.getElementsByClassName("glide"));
for (let i = 0; i < gliders.length; i++) {
    gliders[i].id = "glide__" + i;
    let glide = new Glide('#glide__' + i, {
        type: 'carousel',
        perView: 1,
        focusAt: 'center',
        autoplay: gliders[i].dataset.glideSpeed,
        hoverpause: true
    }).mount({});
    if (parseInt(gliders[i].dataset.glideSize) === 1) {
        glide.disable();
    }
}


const scaleFontToWidthDivs = document.getElementsByClassName("scaleFontToWidth");
flexFont = () => {
    [...scaleFontToWidthDivs].forEach((element) => {
        let fontSize = element.offsetWidth * 0.1 + 'px';
        [...element.children].forEach((child) => {
            child.style.fontSize = fontSize;
        });
    });
};

if (scaleFontToWidthDivs.length !== 0) {
    window.onload = () => flexFont();

    window.addEventListener("resize", () => {
        flexFont();
    }, false)
}
